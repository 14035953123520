@font-face {
    font-family: "Unbounded";
    src: local("Unbounded"),
     url("../assets/font/Unbounded/Unbounded-VariableFont_wght.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
font-family: "Montserrat";
src: local("Montserrat"),
    url("../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
font-weight: 500;
}

.header_first_row, .header_second_row, .footer_row {
padding: 0 9.375%; /* 180px */
}

.header_first_row {
display: flex;
justify-content: space-between;
height: 71px;
align-items: center;
}

.footer_row {
display: flex;
justify-content: space-between;
margin: 24px 0;
}

.header_second_row {
background-color: #EDF2FF;
display: flex;
flex-direction: row;
justify-content: space-between;
}

.header_second_row__title {
font-family: 'Unbounded', san-serif;
font-weight: 500;
font-size: 40px;
margin-top: 46px;
margin-bottom: 0;
line-height: 52px;
}

.header_second_row__article {
font-family: 'Montserrat', san-serif;
font-weight: 500;
font-size: 18px;
line-height: 23,4px;
margin-top: 10px;
margin-bottom: 32px;
}

.header_options {
margin-bottom: 46px;
gap: 32px !important;
}


.illustration {
position: absolute;
top: 19px;
right: 9.375%; /* 180px */
}

.header_options__option > .ant-select-selector{
min-height: 56px !important;
/* padding: 0 14px 0 16px !important;
padding-inline: 14px 16px !important; */
border-radius: 4px !important;
border: 1px solid #D2DDF2 !important;
}

.header_options__option >.ant-select-selector > .ant-select-selection-placeholder {
align-self: center;
font-family: 'Montserrat', sans-serif;
font-weight: 500;
font-size: 16px;
color: #A0A6BF;
}

/* @media screen and (min-width: 1541px) and (max-width: 1920px) {
.header_first_row, .header_second_row {
padding: 0 180px;
}
} */


@media screen and (max-width: 1919px) {   /* страница 1540 */
    .header_first_row, .header_second_row, .footer_row {
        padding: 0 5.974026%; /* 92px */
    }

    .illustration {
        top: 19px;
        right: 5.974026%;
    }

    .header_options {
        gap: 24px !important;
    }

    .header_options__option {
        width: 436px !important;
    }

}

@media screen and (max-width: 1539px) {  /* страница 1366  */
    .header_first_row, .header_second_row, .footer_row {
        padding: 0 4.68521%;  /* 64px */
    }

    .header_options__option {
        width: 395px !important;
    }

    .illustration {
        top: 19px;
        right: 3.6603%; /*50px */
    }
}

@media screen and (max-width: 1365px) {   /* страница 1024 */
    .header_first_row, .header_second_row, .footer_row {
        padding: 0 3.125%; /* 32px */
    }

    .header_second_row__title {
        font-size: 32px;
        line-height: 41.6px;
        margin-top: 32px;
    }

    .header_second_row__article {
        font-size: 16px;
        line-height: 20.8px;
        margin-top: 8px;
        margin-bottom: 16px;
    }

    .header_options {
        display: flex;
        flex-direction: column;
        gap: 8px !important;
        margin-bottom: 26px;
    }

    .header_options__option {
        width: 468px !important;
    }

    .illustration {
        width: 345px;
        height: 203px;
        top: 44px;
        right: 3.02734%; /* 31px */
    }
}

@media screen and (max-width: 1023px) {   /* страница 768 */

    .header_first_row, .header_second_row, .footer_row {
        padding: 0 4.16666667%; /* 32px */
    }

    .header_second_row__title {
        font-size: 24px;
        line-height: 31.2px;
    }

    .header_second_row__article {
        font-size: 14px;
        line-height: 18.2px;
        margin-bottom: 24px;
    }

    .header_options {
        margin-bottom: 32px;
    }

    .header_options__option {
        width: 344px !important;
    }

    .illustration {
        width: 287px;
        height: 169px;
        top: 74px;
        right: 37px;
    }
}

@media screen and (max-width: 767px) {   /* страница 375 */
    .header_first_row, .header_second_row, .footer_row {
        padding: 0 4.2666%; /* 16px */
    }

    .header_first_row {
        justify-content: center;
        height: 46px;
    }

    .footer_row {
        margin: 16px 0;
    }

    .header_first_row__logo {
        height: 30px;
    }

    .header_first_row__telegram {
        display: none;
    }

    .header_second_row__title { 
        margin-top: 20px;
        margin-right: 80px;
    }

    .header_second_row__article {
        margin-right: 42px;
        margin-bottom: 16px;
    }

    .header_options {
        gap: 10px !important;
        margin-bottom: 20px;
    }

    .illustration {
        display: none;
    }
} 


@media screen and (max-width: 374px) {   /* меньше 375 */
  .header_options,
  .header_options > .ant-space-item,
  .header_second_row > .ant-space > .ant-space-item  {
    width: 100%;
  }

   .header_options__option {
    width: 100% !important;
  }

}
/* .card_grid {
    margin-top: 16px;
    gap: 8px;
} */

.card_description {
    margin-bottom: 12px;
    margin-top: 4px; /* +12px добавляется от margin в Divider */
}

.card_item {
    border: 1px solid #BFC3D5;
    border-radius: 10px;
    height: max-content;
}

.ant-card-body {
    margin: 22px 16px 16px 16px !important;
    padding: 0 !important;
    
}

.ant-collapse-content-box {
    padding: 0 0 !important;
}

.more:hover {
    background-color: #F2F9FE;
}

.more:active {
    background-color: #649BE9;
    color: #FFFFFF;
}

.card_description__leftText {
    color: #6A6E7E; 
    margin: 0;
    font-size: 16px;
    line-height: 24px;
}

.card_description__rightText {
    color: '#4D5163'; 
    margin: 0;
    font-size: 16px;
    line-height: 24px;
}

.card_description__rightText p {
    margin-top: 0;
    margin-bottom: 0;
}

.text_overflow_ellipsis { 
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.wrapper {
  min-height: 100vh;
}

.card_wrapper {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 32px 9.375%;  /*32px 180px */
  padding: 32px 9.375% 16px 9.375%; /* снизу 16px добавляется засчет margin-bottom ; у карточки */
}

.my-masonry-grid {
  gap: 24px !important;
  /* display: -webkit-box; /* Not needed if autoprefixing */
  /*display: -ms-flexbox; /* Not needed if autoprefixing */
  /* display: flex;  */
 /* margin-left: -24px; /* gutter size offset */
  /* width: 100%;  */
 /* gap: 24px; */
}
/* gutter size */
/* .my-masonry-grid > div {
  padding-left: 24px; 
  background-clip: padding-box;
} */

/* Style your items */
 /* change div to reference your elements you put in <Masonry> */
.my-masonry-grid > div > div { 
  margin-bottom: 16px;
}

.card_wrapper > .ant-space-item {
  width: 100%;
}
/* 
.card_wrapper__flex {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  column-fill: auto;
  gap: 24px;
} */

.card_item {
  page-break-inside: avoid;
  break-inside: avoid; 
  /* margin-bottom: 16px;  для создания расстояния между "строками" */
}

.loading_screen {
  background-color: #F5F5F5;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-grow: 1; 
  padding-top: 50px; 
  padding-bottom: 50px;
}


@media screen and (max-width: 1919px) {   /* страница 1540 */

  .card_wrapper {
    padding: 32px 5.974026%; /* 92px */
  }

  .card_wrapper__flex {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }

}

@media screen and (max-width: 1539px) {  /* страница 1366  */

  .card_wrapper {
    padding: 32px 4.68521%; /* 64px */
  }

}

@media screen and (max-width: 1365px) {   /* страница 1024 */

  .card_wrapper {
   /* padding: 24px 3.125%; /* 32px */
    padding: 24px 3.125% 0 3.125%; /* снизу 24px добавляется засчет margin-bottom ; у карточки */
  }

  .my-masonry-grid > div > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 24px;
  }

  /* .card_item {
    margin-bottom: 24px;
  } */

  .card_wrapper__flex {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

}

@media screen and (max-width: 1023px) {   /* страница 768 */

  .card_wrapper {
    padding: 32px 4.16666667% 16px 4.16666667%; /* 32px */
  }
  
  .my-masonry-grid {
    gap: 16px !important; /* gutter size offset */
  }

  /* .my-masonry-grid > div {
    padding-left: 16px; /* gutter size 
    background-clip: padding-box;
  } */

  /* Style your items */
  .my-masonry-grid > div > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 16px;
  }

  /* .card_item {
    margin-bottom: 16px;
  } */

  .card_wrapper__flex {
    column-gap: 16px;
  }

}

@media screen and (max-width: 767px) {   /* страница 375 */

  .card_wrapper {
    padding: 24px 4.8% 32px 4.2666%; /* 4.8% - 18px; 4.2666% - 16px */
  }

  .card_wrapper__flex {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

} 
